// next
import { useRouter } from 'next/router';
// routes
import { Role } from '@common/@types/auth';
import { blockUserNotInRoles } from '../routes/NavConfig';
import {
  PATH_COACHING,
  PATH_MANAGEMENT,
  PATH_RECOMMENDATIONS,
  PATH_STUDYREEL,
  PATH_TESTING,
} from '../routes/paths';
import BlacklistedAttributeGuard from './BlacklistedAttributeGuard';

import { DefaultGuardProps } from '@common/@types/guards';
// ----------------------------------------------------------------------

// All paths currently only allow admin access
const ADMIN_ONLY = [Role.ADMIN];

const pageAccessByRole: Record<string, Role[]> = {
  // Management Paths
  [PATH_MANAGEMENT.skillsContent]: ADMIN_ONLY,
  [PATH_MANAGEMENT.skillPlan]: ADMIN_ONLY,
  [PATH_MANAGEMENT.manageSkills]: ADMIN_ONLY,
  [PATH_MANAGEMENT.studentAppRoster]: ADMIN_ONLY,
  // Testing Paths
  [PATH_TESTING.bulkTestAssign]: ADMIN_ONLY,
  [PATH_TESTING.gradeClearedTestAssignment]: ADMIN_ONLY,
  [PATH_TESTING.nextBestTest]: ADMIN_ONLY,
  [PATH_TESTING.testHistory]: ADMIN_ONLY,
  [PATH_TESTING.testInventory]: ADMIN_ONLY,
  [PATH_TESTING.testAssignments]: ADMIN_ONLY,
  [PATH_TESTING.activeAssignments]: ADMIN_ONLY,
  // Recommendation Paths
  [PATH_RECOMMENDATIONS.skillsOverride]: ADMIN_ONLY,
  // Path Coaching
  [PATH_COACHING.root]: ADMIN_ONLY,
  [PATH_COACHING.create]: ADMIN_ONLY,
  [PATH_COACHING.edit]: ADMIN_ONLY,
  [PATH_COACHING.view]: ADMIN_ONLY,
  // Path Study Reel
  [PATH_STUDYREEL.subpage]: ADMIN_ONLY,
  [PATH_STUDYREEL.ap_inventory]: ADMIN_ONLY,
  [PATH_STUDYREEL.clipReview.root]: ADMIN_ONLY,
  [PATH_STUDYREEL.clipReview.create]: ADMIN_ONLY,
  [PATH_STUDYREEL.clipReview.edit]: ADMIN_ONLY,
  [PATH_STUDYREEL.clipReview.view]: ADMIN_ONLY,
};

const PATH_USER_NAME_EDIT_REGEX = /\/user\/.+\/edit/;

export default function PageGuard({ children }: DefaultGuardProps) {
  const router = useRouter();

  let blacklistFunction = undefined;
  if (pageAccessByRole[router.pathname]) {
    blacklistFunction = blockUserNotInRoles(...pageAccessByRole[router.pathname]);
  } else if (PATH_USER_NAME_EDIT_REGEX.test(router.pathname)) {
    blacklistFunction = blockUserNotInRoles(Role.ADMIN);
  }

  return (
    <BlacklistedAttributeGuard hasContent blacklistFunction={blacklistFunction}>
      {children}
    </BlacklistedAttributeGuard>
  );
}
